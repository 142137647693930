import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import { getMonthName } from '@/components/utils/CalendarFunctions'
import { JobInvoicedYTDModel } from '@/classes/viewmodels'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseJobInvoicedFilter(filter)
    const url = `jobinvoiced/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummariesYTD(filter, monthlySettingSummaries) {
    const serialised = this.serialiseJobInvoicedFilter(filter)
    const url = `jobinvoiced/list/ytd/${serialised}`
    const result = await FqApi.get(url)

    // Process YTD data and merge with monthly settings
    const data = []
    for (let i = 1; i <= filter.fiscalDuration; i++) {
        const object = result.data.find(j => j.fiscalMonthOrder === i)
        const monthYear = this.getMonthYear(filter.dateFrom, i)

        const setting = monthlySettingSummaries.find(k => k.month === monthYear.month && k.year === monthYear.year) || {}

        const jobInvoicedYTDModel = object ? new JobInvoicedYTDModel(object) : new JobInvoicedYTDModel({
            monthName: getMonthName(monthYear.month),
            fiscalMonthOrder: i,
            month: monthYear.month,
            year: monthYear.year
        })

        jobInvoicedYTDModel.monthlyTarget = setting.monthlyTarget || 0
        jobInvoicedYTDModel.workingDays = setting.workingDays || 0

        data.push(jobInvoicedYTDModel)
    }

    return data
  },
  getMonthYear(dateString, monthsToAdd) {
      const date = new Date(dateString)
      const month = date.getMonth() + monthsToAdd
      const year = date.getFullYear() + Math.floor((month - 1) / 12)
      return { month: (month % 12) || 12, year }
  },
  serialiseJobInvoicedFilter(filter) {
    const entityProto = require('../../assets/proto/jobinvoiced/JobInvoicedFilter.proto')
    const filterMessage = entityProto.JobInvoicedFilter.JobInvoicedFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
