<template>
  <div v-if="$userInfo">
    <app-detail-header :show-delete="false"
      :show-new="false"
      :show-save="false"
      :show-cancel="false"
      :title="jobInvoicedObj[filter.selectedType]"
      :show-print="false" />
    <div class="tile is-ancestor is-parent">
      <div class="tile is-vertical is-parent">
        <job-invoiced-menu-bar v-model="currentFiscalYearSettings"
          :is-loading="isLoading"
          :total-jobs="totalJobs"
          :total-amount="totalAmount"
          :include-gst="includeGst"
          :current-month="currentMonth"
          :current-year="currentYear"
          :fiscal-year-name="fiscalYearName"
          @selected-type="changeType"
          @filter-list="filterList" />
        <div v-if="filter.selectedType === 0"
          class="tile is-child box">
          <bulma-table class="is-striped is-narrow is-fullwidth is-hoverable"
            :columns="columns"
            :data="sortedData"
            :total-rows="entitySummaries.length"
            :page-size="entitySummaries.length"
            :is-loading="isLoading"
            :sort-column="sortColumn"
            :sort-order="sortOrder"
            :show-pagination="false"
            @sort="sort"
            :has-sticky-header="true"
            sticky-header-top="10.25rem">
            <tr v-for="(item, index) in sortedData"
              :key="index"
              :class="{ 'is-selected' : selectedRow === index }"
              @click="highlightSelected(index, $event)">
              <td class="col-5"><a @click="quoteClick(item.quoteId)">{{ item.quoteNoRef }}</a></td>
              <td class="col-8">{{ item.jobStageDesc }}</td>
              <td class="col-10">{{ item.owner }}</td>
              <td class="col-5">{{ item.rego }}</td>
              <td class="col-12">{{ item.makeModel }}</td>
              <td class="col-10">{{ item.debtorName }}</td>
              <td class="col-5">{{ $filters.formatDateLocale(item.invoiceDate, $userInfo.locale) }} </td>
              <td class="col-5">{{ item.invoiceNoRef }}</td>
              <td class="has-text-right col-5">{{ $filters.formatNumber(item.totalIncGst, $userInfo.locale) }}</td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </div>
        <div v-if="filter.selectedType === 1"
          class="tile is-child box">
          <bulma-table class="is-striped is-narrow is-fullwidth is-hoverable"
            :columns="columns"
            :data="sortedData"
            :total-rows="entitySummaries.length"
            :is-loading="isLoading"
            :sort-column="sortColumn"
            :sort-order="sortOrder"
            :show-pagination="false"
            @sort="sort"
            :has-sticky-header="true"
            sticky-header-top="10.25rem">
            <tr v-for="(item, index) in sortedData"
              :key="'item'+index"
              :class="{ 'is-selected' : selectedRow === index }"
              @click="highlightSelected(index, $event)">
              <td class="col-5 has-text-weight-bold">{{ item.monthName }} {{ item.year }}</td>
              <td class="col-5 has-text-centered">{{ item.workingDays }}</td>
              <td class="col-5 has-text-centered">
                <div>{{ item.totalInvoices }}</div>
              </td>
              <td class="has-text-right col-5">{{ getAvgDailyInvoiced(item.totalIncGst, item.workingDays) | formatCurrency($userInfo.locale) }}</td>
              <td class="has-text-right col-5">{{ getMonthlyTarget(item.monthlyTarget) | formatCurrency($userInfo.locale) }}</td>
              <td class="has-text-right col-5">{{ item.totalIncGst | formatCurrency($userInfo.locale) }}</td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import JobInvoicedMenuBar from './JobInvoicedMenuBar'
import JobInvoicedService from './JobInvoicedService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import BulmaTable from '@/components/BulmaTable'
import { DetailColumns, MonthlyColumns } from './columns'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import { ItemCategoryTypes } from '@/enums'
import { getThisMonthDates, getFiscalYearDates } from '@/components/utils/CalendarFunctions'
import { CompanySettingService } from '@/services'
import { getFiscalYearName } from '../../components/utils/CalendarFunctions'

export default {
  name: 'JobInvoicedView',
  components: {
    AppDetailHeader,
    BulmaTable,
    JobInvoicedMenuBar
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  data() {
    return {
      jobInvoicedObj: {
        0: 'Job Invoiced',
        1: 'Job Invoiced Year to Date'
      },
      isLoading: false,
      entitySummaries: [],
      totalRows: 0,
      selectedQuoteId: null,
      selectedJobNo: null,
      sortColumn: DetailColumns[0].name,
      sortOrder: DetailColumns[0].defaultOrder,
      filter: {
        dateFrom: null,
        dateTo: null,
        fiscalStart: 1,
        fiscalDuration: 12,
        selectedType: 0,
        selectedRow: null,
        sortColumn: 'QuoteNoRef',
        sortOrder: 'asc'
      },
      toggle: true,
      selectedRow: null,
      currentMonth: 0,
      currentYear: 0,
      fiscalYearName: null,
      currentFiscalYearSettings: [],
      currentFiscalStartDate: new Date()
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    includeGst() {
      return this.$company?.info?.isGst
    },
    columns() {
      let cols = DetailColumns

      if (this.filter.selectedType === 0) {
        if (this.includeGst) {
          cols[8].title = 'Total (Inc GST)'
        }
      } else {
        cols = _cloneDeep(MonthlyColumns)
        if (this.includeGst) {
          cols[5].title = 'Total (Inc GST)'
        }
      }
      return cols
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    sortedData() {
      const data = _cloneDeep(this.entitySummaries)
      return data.sort((a, b) => {
        const modifier = this.sortOrder === 'desc' ? -1 : 1
        if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier
        if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier
        return 0
      })
    },
    totalJobs() {
      const quoteNos = [...new Set(this.entitySummaries.map((job) => job.quoteNo))]
      return quoteNos.length
    },
    totalAmount() {
      let total = this.entitySummaries.reduce((acc, item) => {
        return acc + item.totalIncGst
      }, 0)
      return roundAwayFromZero(total, 2)
    }
  },
  watch: {},
  async created() {
    const today = new Date()
    this.currentMonth = today.getMonth() + 1
    this.currentYear = today.getFullYear()

    const [dateFrom] = getThisMonthDates()
    this.filter.dateFrom = dateFrom.toISOString()
    this.filter.dateTo = new Date(new Date().setHours(0, 0, 0, 0)).toISOString() // dateTo.toISOString()
    this.filter.fiscalDuration = this.$company.setting.fiscalDuration

    this.getEntitySummaries()
    await this.getCompanyFiscalYearSetting()
  },
  mounted() {},
  methods: {
    filterList() {
      this.persistFilter()
      this.getEntitySummaries()
    },
    changeType(type) {
      this.filter.selectedType = type
      this.selectedRow = null
      this.filter.selectedRow = null

      if (type === 0) {
        const [firstDayOfMonth] = getThisMonthDates()
        this.filter.dateFrom = firstDayOfMonth.toISOString()
        this.filter.sortColumn = 'QuoteNoRef'
      } else {
        this.filter.dateFrom = this.currentFiscalStartDate.toISOString()
        this.filter.sortColumn = 'FiscalMonthOrder'
      }

      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      // console.log('job invoiced filter = ' + JSON.stringify(this.filter))
      this.isLoading = true

      if (this.filter.selectedType === 0) {
        this.entitySummaries = await JobInvoicedService.getEntitySummaries(this.filter)
      } else {
        this.entitySummaries = await JobInvoicedService.getEntitySummariesYTD(this.filter, this.currentFiscalYearSettings)
      }

      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0

      this.isLoading = false
    },
    async getCompanyFiscalYearSetting() {
      const currentMonth = new Date().getMonth() + 1 // JavaScript months are zero-indexed
      const currentYear = new Date().getFullYear()
      // return this.$filters.formatCurrency(this.getSum(jobs), this.$userInfo.locale).split('.')
      const allFiscalYears = await CompanySettingService.getAllFiscalYear()

      if (allFiscalYears.length !== 0) {
        const currentFiscalYear = allFiscalYears.filter(i => i.year === currentYear && i.month === currentMonth)
        if (currentFiscalYear.length !== 0) {
          const startDate = allFiscalYears.find(r => r.fiscalYearName === currentFiscalYear[0].fiscalYearName)
          const startYear = startDate.year
          const startMonth = startDate.month - 1

          this.fiscalYearName = startDate.fiscalYearName
          this.currentFiscalStartDate = new Date(new Date(startYear, startMonth).setHours(0, 0, 0, 0))
          this.currentFiscalYearSettings = allFiscalYears.filter(i => i.fiscalYearName === this.fiscalYearName)
        } else {
          const firstFyYear = allFiscalYears[0].year
          const firstFyMonth = allFiscalYears[0].month - 1
          const firstFyDate = new Date(firstFyYear, firstFyMonth)

          const yearDiff = currentYear - firstFyDate.getFullYear()
          const monthDiff = currentMonth - firstFyDate.getMonth()
          const totalMonths = yearDiff * 12 + monthDiff
          const fiscalDiff = Math.floor(totalMonths / this.$company.setting.fiscalDuration)
          this.currentFiscalStartDate = new Date(firstFyDate.setMonth(firstFyDate.getMonth() + (fiscalDiff * this.$company.setting.fiscalDuration)))
          this.fiscalYearName = await getFiscalYearName(this.currentFiscalStartDate, this.$company.setting.fiscalDuration)
        }
      } else {
        const [fiscalYearStartDate] = getFiscalYearDates(this.$company.setting.fiscalStart, this.$company.setting.fiscalDuration, true)
        this.fiscalYearName = await getFiscalYearName(fiscalYearStartDate, this.$company.setting.fiscalDuration)
        this.currentFiscalStartDate = fiscalYearStartDate
      }
    },
    getAvgDailyInvoiced(total, days) {
      let amount = 0
      if (days && days !== 0) {
        amount = roundAwayFromZero(total / days, 2)
      }
      return amount
    },
    getMonthlyTarget(value) {
      let target = 0
      if (value) {
        target = roundAwayFromZero(value, 0)
      }
      return target
    },
    quoteClick(quoteId) {
      this.setQuoteReturnRoute(this.$route)
      this.editStoreQuote(quoteId)
    },
    sort(column, order) {
      this.sortColumn = column
      this.sortOrder = order
      this.filter.sortColumn = this.sortColumn
      this.filter.sortOrder = this.sortOrder
      this.persistFilter()
    },
    highlightSelected(index, event) {
      this.selectedRow = index
      this.filter.selectedRow = index
      this.persistFilter()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
        this.selectedRow = this.filter.selectedRow
        this.sortColumn = this.filter.sortColumn
        this.sortOrder = this.filter.sortOrder
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col-5 {
  width: 5%;
}
.col-8 {
  width: 8%;
}
.col-10 {
  width: 10%;
}
.col-12 {
  width: 12%;
}
</style>