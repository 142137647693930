<template>
  <div class="tile is-child box has-sticky-tile"
    style="margin-bottom: 1rem !important">
    <div class="is-flex is-align-items-center"
      style="height: 60px">
      <div class="toggle-buttons-vertical ml-2 is-flex is-flex-direction-column mt-2 mb-1">
        <span class="button m-0"
          style="border-radius: 5px 5px 0px 0px;"
          :class="{ 'is-primary is-selected' : selectedType === 0}"
          @click="changeType(0)">
          <span>Job Invoiced This Month</span>
        </span>
        <span class="button m-0"
          style="border-radius: 0px 0px 5px 5px;"
          :class="{ 'is-primary is-selected' : selectedType === 1}"
          @click="changeType(1)">
          <span>Job Invoiced Year to Date</span>
        </span>
      </div>
      <div v-if="selectedType === 0"
        class="is-flex is-flex-direction-row is-justify-content-space-evenly"
        style="height: 60px; width: 100%;">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Total Jobs</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ totalJobs }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Working Days</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ monthlyWorkingDays }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Daily Target</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ this.$filters.formatCurrency(dailyTarget, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
        <!-- <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Avg. Daily Invoiced Amount (MTD)</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ this.$filters.formatCurrency(avgDailyInvAmount, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div> -->
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Amount Waiting in JCNI</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ totalNotInvoiced }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Monthly Target</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ this.$filters.formatCurrency(monthlyTarget, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Invoiced Amount (MTD)</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ this.$filters.formatCurrency(totalAmount, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
      </div>
      <div v-else
        class="is-flex is-flex-direction-row is-justify-content-space-evenly"
        style="height: 60px; width: 100%;">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Daily Target</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ this.$filters.formatCurrency(dailyTargetYTD, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Yearly Target</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ this.$filters.formatCurrency(yearlyTarget, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Invoiced YTD (FY {{ fiscalYearName }})</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ this.$filters.formatCurrency(totalAmount, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JcniService from '@/views/jobsCompletedNotInvoiced/JcniService'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'JobInvoicedMenuBar',
  props: {
    value: null,
    totalJobs: {
      type: Number,
      value: 0
    },
    totalAmount: {
      type: Number,
      value: 0
    },
    fiscalYearName: {
      type: String,
      value: null
    },
    currentMonth: {
      type: Number,
      value: 0
    },
    currentYear: {
      type: Number,
      value: 0
    },
    isLoading: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      selectedType: 0,
      jcniRecords: []
    }
  },
  computed: {
    avgDailyInvAmount() {
      let avgAmount = 0
      if (this.monthlyWorkingDays !== 0) {
        avgAmount = roundAwayFromZero(this.totalAmount / this.monthlyWorkingDays, 2)
      }
      return avgAmount
    },
    totalNotInvoiced() {
      const total = this.jcniRecords.reduce((acc, item) => {
        return acc + item.totalNotInvoiced
      }, 0)
      return this.$filters.formatCurrency(total, this.$userInfo.locale)
    },
    dailyTarget() {
      let target = 0
      if (this.monthlyWorkingDays !== 0) {
        target = roundAwayFromZero(this.monthlyTarget / this.monthlyWorkingDays, 2)
      }
      return target
    },
    dailyTargetYTD() {
      let target = 0
      if (this.yearlyWorkingDays !== 0) {
        target = roundAwayFromZero(this.yearlyTarget / this.yearlyWorkingDays, 2)
      }
      return target
    },
    monthlyTarget() {
      let target = 0
      const setting = this.value.find((i) => i.month === this.currentMonth && i.year === this.currentYear)
      if (setting !== undefined) {
        target = setting.monthlyTarget
      }
      return roundAwayFromZero(target, 0)
    },
    monthlyWorkingDays() {
      let days = 0
      const setting = this.value.find((i) => i.month === this.currentMonth && i.year === this.currentYear)
      if (setting !== undefined) {
        days = setting.workingDays
      }
      return days
    },
    yearlyTarget() {
      const sumMonthlyTarget = this.value.reduce((sum, setting) => {
        if (setting.fiscalYearName === this.fiscalYearName) {
          sum += setting.monthlyTarget || 0
        }
        return sum
      }, 0)
      return roundAwayFromZero(sumMonthlyTarget, 0)
    },
    yearlyWorkingDays() {
      const sumMonthlyWorkingDays = this.value.reduce((sum, setting) => {
        if (setting.fiscalYearName === this.fiscalYearName) {
          sum += setting.workingDays || 0
        }
        return sum
      }, 0)
      return sumMonthlyWorkingDays
    }
  },
  watch: {},
  async created() {
    const response = await JcniService.getJobsCompletedNotInvoiced()
    this.jcniRecords = response
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    changeType(type) {
      this.selectedType = type
      this.$emit('selected-type', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.spins {
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tile.is-child.has-sticky-tile {
  position: sticky;
  top: 3.25rem;
  z-index: 31;
}
.header-text-divider {
  border-bottom: 1px solid lightgray;
}
</style>