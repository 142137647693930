/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.JobInvoicedFilter = (function() {

    /**
     * Namespace JobInvoicedFilter.
     * @exports JobInvoicedFilter
     * @namespace
     */
    var JobInvoicedFilter = {};

    JobInvoicedFilter.JobInvoicedFilterModel = (function() {

        /**
         * Properties of a JobInvoicedFilterModel.
         * @memberof JobInvoicedFilter
         * @interface IJobInvoicedFilterModel
         * @property {string|null} [dateFrom] JobInvoicedFilterModel dateFrom
         * @property {string|null} [dateTo] JobInvoicedFilterModel dateTo
         * @property {number|null} [selectedType] JobInvoicedFilterModel selectedType
         * @property {number|null} [fiscalDuration] JobInvoicedFilterModel fiscalDuration
         * @property {string|null} [sortColumn] JobInvoicedFilterModel sortColumn
         * @property {string|null} [sortOrder] JobInvoicedFilterModel sortOrder
         */

        /**
         * Constructs a new JobInvoicedFilterModel.
         * @memberof JobInvoicedFilter
         * @classdesc Represents a JobInvoicedFilterModel.
         * @implements IJobInvoicedFilterModel
         * @constructor
         * @param {JobInvoicedFilter.IJobInvoicedFilterModel=} [p] Properties to set
         */
        function JobInvoicedFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * JobInvoicedFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        JobInvoicedFilterModel.prototype.dateFrom = null;

        /**
         * JobInvoicedFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        JobInvoicedFilterModel.prototype.dateTo = null;

        /**
         * JobInvoicedFilterModel selectedType.
         * @member {number|null|undefined} selectedType
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        JobInvoicedFilterModel.prototype.selectedType = null;

        /**
         * JobInvoicedFilterModel fiscalDuration.
         * @member {number|null|undefined} fiscalDuration
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        JobInvoicedFilterModel.prototype.fiscalDuration = null;

        /**
         * JobInvoicedFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        JobInvoicedFilterModel.prototype.sortColumn = null;

        /**
         * JobInvoicedFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        JobInvoicedFilterModel.prototype.sortOrder = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * JobInvoicedFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        Object.defineProperty(JobInvoicedFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * JobInvoicedFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        Object.defineProperty(JobInvoicedFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * JobInvoicedFilterModel _selectedType.
         * @member {"selectedType"|undefined} _selectedType
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        Object.defineProperty(JobInvoicedFilterModel.prototype, "_selectedType", {
            get: $util.oneOfGetter($oneOfFields = ["selectedType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * JobInvoicedFilterModel _fiscalDuration.
         * @member {"fiscalDuration"|undefined} _fiscalDuration
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        Object.defineProperty(JobInvoicedFilterModel.prototype, "_fiscalDuration", {
            get: $util.oneOfGetter($oneOfFields = ["fiscalDuration"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * JobInvoicedFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        Object.defineProperty(JobInvoicedFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * JobInvoicedFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @instance
         */
        Object.defineProperty(JobInvoicedFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified JobInvoicedFilterModel message. Does not implicitly {@link JobInvoicedFilter.JobInvoicedFilterModel.verify|verify} messages.
         * @function encode
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @static
         * @param {JobInvoicedFilter.IJobInvoicedFilterModel} m JobInvoicedFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobInvoicedFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(10).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(18).string(m.dateTo);
            if (m.selectedType != null && Object.hasOwnProperty.call(m, "selectedType"))
                w.uint32(24).int32(m.selectedType);
            if (m.fiscalDuration != null && Object.hasOwnProperty.call(m, "fiscalDuration"))
                w.uint32(32).int32(m.fiscalDuration);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            return w;
        };

        /**
         * Encodes the specified JobInvoicedFilterModel message, length delimited. Does not implicitly {@link JobInvoicedFilter.JobInvoicedFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @static
         * @param {JobInvoicedFilter.IJobInvoicedFilterModel} message JobInvoicedFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobInvoicedFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobInvoicedFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {JobInvoicedFilter.JobInvoicedFilterModel} JobInvoicedFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobInvoicedFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.JobInvoicedFilter.JobInvoicedFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.dateFrom = r.string();
                    break;
                case 2:
                    m.dateTo = r.string();
                    break;
                case 3:
                    m.selectedType = r.int32();
                    break;
                case 4:
                    m.fiscalDuration = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a JobInvoicedFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JobInvoicedFilter.JobInvoicedFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JobInvoicedFilter.JobInvoicedFilterModel} JobInvoicedFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobInvoicedFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return JobInvoicedFilterModel;
    })();

    return JobInvoicedFilter;
})();

module.exports = $root;
